@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  font-family: 'Inter', sans-serif;
}

.text-limit {
  display: block;
  height: 80px;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
}

.flip {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.center-web {
  text-align: -webkit-center;
}

.montserrat {
  font-family: 'Montserrat', sans-serif;
}

.lato {
  font-family: 'Lato', sans-serif;
}

@media (min-width:1140px) {
  .cardPesan {
    box-shadow: 12px 12px 24px rgba(144, 202, 249, 0.24);
    border-radius: 32px;
  }

  .cardTestimoni {
    /* Shades/White */
    background: #FFFFFF;

    /* Normal */
    box-shadow: 12px 12px 24px rgba(144, 202, 249, 0.24);
    border-radius: 16px;
  }
}

.drop-shadow-normal {
  box-shadow: 8px 8px 32px #E3F2FD;
}

.drop-shadow-top {
  box-shadow: 0px -8px 32px #E3F2FD;
}

.drop-shadow-bottom {
  box-shadow: 0px 8px 32px #CDCDCD;
}

.iframe-16-9-ratio {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
  overflow: hidden;
  width: 100%;
}

.underlined {
  position: relative;
}

.underlined:before {
  content: "";
  position: relative;
  width: 100%;
  height: 2.45em;
  left: 0;
  width: 100%;
  z-index: -10;
  background-image: url(assets/vectors/misc/underline.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.underlined:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2.45em;
  left: 0;
  width: 100%;
  z-index: -10;
  background-image: url(assets/vectors/misc/underline.svg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.cardTestimoni {
  /* Shades/White */
  background: #FFFFFF;

  /* Normal */
  box-shadow: 12px 12px 24px rgba(144, 202, 249, 0.24);
  border-radius: 16px;
}

.BGright {
  position: absolute;
  width: calc(100vw - 55%);
  height: 174px;

  /* Primary/Bright */
  background: #90CAF9;
  border-radius: 174px 0px 0px 0px;
}

.BGleft {
  position: absolute;
  width: calc(100vw - 58%);
  height: 174px;

  background: #90CAF9;
  border-radius: 0px 174px 0px 0px;
}

.whatsapp {
  box-sizing: border-box;

  position: fixed;
  width: 80px;
  height: 80px;

  /* Success/Default */
  background: #4CAF50;
  /* Shades/Trans-Black */
  border: 4px solid rgba(0, 0, 0, 0.2);
  border-radius: 60px;
}

.custom-calendar {
  box-shadow: none !important;
  border-radius: 0 !important;
  z-index: inherit !important;
}

.progress-line>* {
  position: relative;
  z-index: 1;
  height: 100%;
}

.progress-line>*:not(:last-child)::before {
  position: absolute;
  display: block;
  content: "";
  top: 80%;
  right: 20%;
  transform: translateX(100%);
  height: 2px;
  width: calc(25vw - 32px);
  z-index: 2;
}

.progress-line>*:last-child::after {
  position: absolute;
  display: block;
  content: "";
  top: 80%;
  right: 20%;
  transform: translateX(100%);
  height: 2px;
  width: calc(400% - 32px);
  z-index: 2;
  background: #FFFFFF;
}

.vertical-progress-line>* {
  position: relative;
  z-index: 1;
  height: 100%;
}

.vertical-progress-line>*:not(:last-child)::before {
  position: absolute;
  content: "";
  top: 0;
  transform: translateX(550%);
  height: calc(100% + 30px);
  width: 2px;
  z-index: -1;
}

.vertical-progress-line>*:last-child::before {
  position: absolute;
  content: "";
}

.swiper-wrapper {
  overflow: visible !important;
  /* padding-top: 1.5rem;
  padding-bottom: 1.5rem; */
}

.swiper-pagination-bullet {
  width: 1rem !important;
  height: 1rem !important;
  background-color: white;
  border-radius: 1rem !important;
}

.swiper-pagination-bullet-active {
  width: 4rem !important;
  height: 1rem !important;
  border-radius: 1rem !important;
  background-color: "#2196F3";
}

.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  bottom: 10px;
  left: 0;
  width: 50% !important;
}

.swiper-wrapper .swiper-slide {
  height: auto;
  width: auto;
}

.custom-progress::-webkit-progress-value {
  background-color: #3F83F8; /* Change this to your desired filled color */
}
.custom-progress::-moz-progress-bar {
  background-color: #3F83F8; /* Change this to your desired filled color */
}

@media screen and (min-width: 640px) {
  .swiper-container {
    width: 640px;
  }
}

@media screen and (min-width: 768px) {
  .swiper-container {
    width: 768px;
  }
}

@media screen and (min-width: 720px) {
  .progress-line>*:not(:last-child)::before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    right: 0;
    transform: translateX(100%);
    height: 2px;
    width: 75%;
    z-index: -1;
  }

  .progress-line>*:last-child::after {
    position: absolute;
    display: block;
    content: "";
  }
}

.modal {
  position: fixed; /* Ensure the modal is fixed */
  top: 0; /* Align to the top */
  left: 0; /* Align to the left */
  right: 0; /* Align to the right */
  bottom: 0; /* Align to the bottom */
  z-index: 1000; /* Set a high z-index to appear above other elements */
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  background-color: rgba(0, 0, 0, 0.5); /* Optional: semi-transparent background */
}

.ck-editor__editable {
  min-height: 300px;
  max-height: 400px;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: calc(1rem * 1.5 * 2); /* 1rem font-size * line-height of 1.5 * 2 lines */
}